import { useContext } from "react";
import classNames from "classnames";

import { MapContext } from "../../Map";

import { ReactComponent as CompassBorder } from "./assets/compass_border.svg";
import { ReactComponent as CompassText } from "./assets/compass_text.svg";

import styles from "./Compass.module.scss";

export interface CompassProps {
  bearing: number;
}

const Compass: React.FC<CompassProps> = ({ bearing }) => {
  const { controlSize, controlTheme } = useContext(MapContext);

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles[controlSize]]: true,
        [styles[controlTheme]]: true,
      })}
    >
      <div
        style={{
          display: "flex",
          transform: `rotate(${-bearing}deg)`,
        }}
      >
        <CompassBorder className={styles.compassBorderSvg} />
      </div>
      <CompassText className={styles.compassTextSvg} />
    </div>
  );
};

export default Compass;
