import { useState, useEffect } from "react";
import GitInfo from "react-git-info/macro";

import { TEMPLATES } from "./templates";
import BaseWithHeader from "./templates/BaseWithHeader/BaseWithHeader";
import {
  useGetConfigQuery,
  useGetFeatureListQuery,
  useGetSearchTagsListQuery,
  useHeartbeatPingMutation,
} from "./redux/services/config";
import { errorHandler } from "./utils";
import packageJSON from "../package.json";
import "./App.css";
import { getScreenId } from "./utils/getScreenId";

export enum LAYOUTS {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

function App() {
  const screenId = getScreenId();

  const [uptime, setUptime] = useState<string | null>(null);

  const {
    data,
    isError: isConfigError,
    isLoading: isConfigLoading,
    error: configError,
  } = useGetConfigQuery(screenId);

  const {
    data: featuresResponse,
    isError: isFeaturesError,
    isLoading: isFeaturesLoading,
    error: featuresError,
  } = useGetFeatureListQuery(screenId);

  const {
    data: searchTagsResponse,
    isError: isSearchTagsError,
    isLoading: isSearchTagsLoading,
    error: searchTagsError,
  } = useGetSearchTagsListQuery(screenId);

  const [heartbeatPing] = useHeartbeatPingMutation();

  const { commit, branch } = GitInfo();

  useEffect(() => {
    if (data) {
      console.log(
        `Version: ${packageJSON.version}\nBranch: ${branch}\nCommit hash: ${commit.hash}`,
      );
      console.log(data);

      const currentTime = new Date(Date.now()).toISOString();
      setUptime(currentTime);

      heartbeatPing({
        id: screenId,
        up_since: currentTime,
        screen: { width: window.innerWidth, height: window.innerHeight },
        frontend: { version: packageJSON.version, commit: commit.hash },
      });
    }
  }, [data, heartbeatPing, branch, commit.hash, screenId]);

  useEffect(() => {
    if (uptime) {
      setInterval(() => {
        heartbeatPing({
          id: screenId,
          up_since: uptime,
          screen: { width: window.innerWidth, height: window.innerHeight },
          frontend: { version: packageJSON.version, commit: commit.hash },
        });
      }, 1000 * 60);
    }
  }, [heartbeatPing, screenId, uptime, commit.hash]);

  if (isConfigError) {
    errorHandler(configError);
    return null;
  }

  if (isFeaturesError) {
    errorHandler(featuresError);
    return null;
  }

  if (isSearchTagsError) {
    console.error(searchTagsError);
  }

  if (isConfigLoading || isFeaturesLoading || isSearchTagsLoading)
    return <div>Loading...</div>;
  if (!data) return <div>Config does not exist!</div>;
  if (!featuresResponse) return <div>Feature data does not exist!</div>;

  const { template } = data;

  switch (template) {
    case TEMPLATES.BASE_WITH_HEADER:
      return (
        <BaseWithHeader
          data={data}
          features={featuresResponse?.data}
          searchTags={searchTagsResponse?.data}
          uptime={uptime}
        />
      );
    default:
      return (
        <BaseWithHeader
          data={data}
          features={featuresResponse?.data}
          searchTags={searchTagsResponse?.data}
          uptime={uptime}
        />
      );
  }
}

export default App;
