export type UITheme = "light" | "dark";
export type UISize = "small" | "medium" | "large";

export interface UIStyles {
  theme?: UITheme;
  size?: UISize;
}

export enum ControlSizeScale {
  small = 0.8,
  medium = 1,
  large = 1.2,
}

export enum FullHdResolution {
  y = 1920,
  x = 1080,
}

export enum FourKResolution {
  y = 3840,
  x = 2160,
}
