import { normalize } from "./normalizeString";

export const regexify = (searchTerm: string): RegExp => {
  const normalizedSearchTerm = normalize(searchTerm);

  const result = normalizedSearchTerm
    .replace(/.{1}/g, "($&|[^a-zA-Z0-9])") // any character can be substituted for non-alphanumeric characters
    .replace(/.{16}/g, "$&[^a-zA-Z0-9]?"); // allow any non alphanumeric character after each original character

  return new RegExp(result, "gi");
};
