import { Floors } from "../redux/services/config";

export enum FloorChange {
  UP = "up",
  DOWN = "down",
  NONE = "none",
}

interface FloorDifference {
  amount: number;
  direction: FloorChange;
}

export function getFloorDifference(
  id: number,
  defaultFloorId: number,
  allFloors: Floors,
): FloorDifference {
  const noFloorChange = {
    amount: 0,
    direction: FloorChange.NONE,
  };

  const floors = Object.values(allFloors);

  // Find the default floor and target floor based on their id
  const [defaultFloor, targetFloor] = [defaultFloorId, id].map((floorId) =>
    floors.find((floor) => floor.id === floorId),
  );

  // If either floor is not found, return no difference
  if (!defaultFloor || !targetFloor) return noFloorChange;

  const floorDifference =
    Number(targetFloor.floor) - Number(defaultFloor.floor);

  if (floorDifference === 0) return noFloorChange;

  return {
    // Round the difference to the nearest whole number so that mezzanine floors are counted as 1 floor
    amount: Math.round(Math.abs(floorDifference)),
    direction: floorDifference > 0 ? FloorChange.UP : FloorChange.DOWN,
  };
}

export function getFloorDifferenceString(
  floorDifference: FloorDifference,
): string {
  return floorDifference.amount === 0
    ? "On this level"
    : `${floorDifference.amount} ${floorDifference.amount === 1 ? `level` : `levels`} ${floorDifference.direction}`;
}
