import { useState, useEffect, useCallback } from "react";

import { Feature, useGetFeaturesBySearchQuery } from "../redux/services/config";
import { useAppSelector } from "../redux/hooks";

import { getScreenId } from "../utils/getScreenId";
import { regexify } from "../utils/regexifySearchTerm";
import { normalize } from "../utils/normalizeString";

import { useDebouncedValue } from "./useDebouncedValue";

interface Props {
  features: Feature[];
  searchTerm: string;
  onSearchInputActive: (enabled: boolean) => void;
}

export interface SearchResultStore {
  [featureTitle: string]: Feature[];
}

export const MIN_CHARS = 2;

export const useSearchResults = ({
  features,
  searchTerm,
  onSearchInputActive,
}: Props) => {
  const { onlineMode } = useAppSelector((state) => state.application);

  // Store the search results in an object where the key is the feature name which holds an array of features associated with the same name, so we can return all of them on result click
  const [resultStore, setResultStore] = useState<SearchResultStore>({});
  const [isTitleSearch, setIsTitleSearch] = useState(false);

  const debouncedSearchTerm = useDebouncedValue(
    searchTerm,
    onlineMode ? 2000 : 0,
  );

  const {
    data: onlineFeatures,
    isError,
    isFetching,
  } = useGetFeaturesBySearchQuery(
    { id: getScreenId(), query: debouncedSearchTerm },
    { skip: debouncedSearchTerm.length < MIN_CHARS || !onlineMode },
  );

  // Helper function to add a feature to the result store
  const handleSetResultStore = useCallback((feature: Feature) => {
    setResultStore((prevResultStore) => {
      const header = feature.properties.popup_header;
      const existingFeatures = prevResultStore[header] || [];

      return {
        ...prevResultStore,
        [header]: [...existingFeatures, feature],
      };
    });
  }, []);

  // Effect to handle search logic
  useEffect(() => {
    setResultStore({});

    // If online mode is active and there are online features, use them
    if (onlineMode && !isFetching && onlineFeatures?.data.length && !isError) {
      onlineFeatures.data.forEach(handleSetResultStore);
      setIsTitleSearch(false);
      return;
    }

    // Fallback to offline search if online search doesn't return results or errors
    if (searchTerm.length >= MIN_CHARS) {
      // Reset result store if there are two spaces in a row
      if (/ {2}/.test(searchTerm)) {
        setResultStore({});
        return;
      }

      features.forEach((feature) => {
        const header = feature.properties.popup_header;
        if (
          !header ||
          !regexify(searchTerm).test(normalize(header.toLowerCase()))
        ) {
          return;
        }

        handleSetResultStore(feature);
      });

      setIsTitleSearch(true);
    }
  }, [
    onlineFeatures,
    debouncedSearchTerm,
    features,
    onlineMode,
    isFetching,
    isError,
    searchTerm,
    handleSetResultStore,
  ]);

  // Effect to handle search input active state
  useEffect(() => {
    const hasResults = Object.keys(resultStore).length > 0;
    const shouldActivateSearchInput =
      !onlineMode && searchTerm.length >= MIN_CHARS ? hasResults : true;

    onSearchInputActive(shouldActivateSearchInput);
  }, [resultStore, onlineMode, searchTerm.length, onSearchInputActive]);

  // Effect to clear results if the search term is too short
  useEffect(() => {
    if (searchTerm.length < MIN_CHARS) {
      setResultStore({});
    }
  }, [searchTerm.length]);

  return {
    results: resultStore,
    isTitleSearch,
    debouncedSearchTerm,
    isFetching,
  };
};
