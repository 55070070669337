import { useRef, useEffect, useState, useContext } from "react";
import classNames from "classnames";
import { FloatingButton } from "@livingmap/hippo";

import { useAppSelector } from "../../redux/hooks";
import { Feature, SearchTag } from "../../redux/services/config";

import { parseLanguageObject } from "../../utils/parseLanguageObject";
import { sizeToHippo } from "../../utils/sizeToHippo";
import { is4kScreen } from "../../utils/is4kScreen";

import { UISize } from "../types";

import { MapContext } from "../Map/Map";

import styles from "./SearchTags.module.scss";

interface SearchTagsProps {
  dataQA: string;
  className?: string;
  searchTags: SearchTag[];
  onSearchTagClick: (
    name: string,
    features: Feature[],
    searchTagID: number,
  ) => void;
}

const getButtonWidth = (size: UISize) => {
  switch (size) {
    case "small":
      return 108;
    case "medium":
      return 120;
    case "large":
      return 144;
    default:
      return 120;
  }
};

export default function SearchTags({
  dataQA,
  className,
  searchTags,
  onSearchTagClick,
}: SearchTagsProps) {
  const { controlSize } = useContext(MapContext);
  const { language } = useAppSelector((state) => state.application);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const [balancedTags, setBalancedTags] = useState<SearchTag[][]>([searchTags]);

  useEffect(() => {
    const handleResize = () => {
      if (!containerRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const buttonWidth = getButtonWidth(controlSize); // approximate width of each button depending on kiosk size
      const buttonsPerRow = Math.floor(
        containerWidth / (is4kScreen() ? buttonWidth * 2 : buttonWidth),
      );

      if (searchTags.length <= buttonsPerRow) {
        // all tags fit in one row
        setBalancedTags([searchTags]);
      } else {
        // split into exactly two balanced rows
        const half = Math.ceil(searchTags.length / 2);
        const firstRow = searchTags.slice(0, half);
        const secondRow = searchTags.slice(half);

        setBalancedTags([firstRow, secondRow]);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [controlSize, searchTags]);

  return (
    <div
      data-qa={dataQA}
      ref={containerRef}
      className={classNames(styles.container, className)}
    >
      {balancedTags.map((row, rowIndex) => (
        <div key={rowIndex} className={styles.row}>
          {row.map(({ id, name, features }) => {
            const nameString = parseLanguageObject(name, language) || "";

            return (
              <FloatingButton
                key={id}
                dataQA={`search-tag-${id}`}
                // eslint-disable-next-line react/style-prop-object
                style="filled"
                type="primary"
                text={nameString}
                onClick={() => onSearchTagClick(nameString, features, id)}
                size={sizeToHippo(controlSize)}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}
