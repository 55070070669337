export const getScreenId = () => {
  const screenId = new URLSearchParams(window.location.search).get("screen_id");

  if (!screenId) {
    window.displayErrorScreen("screen_id must be supplied to load app");
    throw new Error("screen_id must be supplied to load app");
  }

  return screenId;
};
