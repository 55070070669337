export const sizeToHippo = (size: "small" | "medium" | "large") => {
  switch (size) {
    case "small":
      return "small";
    case "medium":
      return "regular";
    case "large":
      return "large";
    default:
      return "regular";
  }
};
